import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Footer from './Footer'
import avatar from '../assets/images/ryan-teliczan.jpg'

class Header extends React.Component {
    render() {

        return (
                <header id="header">
                    <nav>
                        <ul>
                            <li><AnchorLink href="#about">About</AnchorLink></li>
                            <li><AnchorLink href="#work">Work</AnchorLink></li>
                            <li><AnchorLink href="#skills">Skills</AnchorLink></li>
                            <li><AnchorLink href="#contact">Contact</AnchorLink></li>
                        </ul>
                    </nav>
                    <div className="inner">
                        <a href="#" className="image avatar"><img src={avatar} alt="" /></a>
                        <h1><strong>Ryan Teliczan</strong><br />
                        Senior Web Developer<br />
                        Seattle, Wa.</h1>
                    </div>
                    <Footer />
                </header>
        )
    }
}

export default Header
