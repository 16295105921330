import React from 'react'
import Template from '../components/layout'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/portfolio-1.jpg'
import thumb02 from '../assets/images/thumbs/portfolio-2.jpg'
import thumb03 from '../assets/images/thumbs/portfolio-3.jpg'
import thumb04 from '../assets/images/thumbs/portfolio-4.jpg'
import thumb05 from '../assets/images/thumbs/portfolio-5.jpg'
import thumb06 from '../assets/images/thumbs/portfolio-6.jpg'

const DEFAULT_IMAGES = [
    { id: '1', thumbnail: thumb01, caption: 'troyhaneylaw.com', description: 'Angular 7 SPA, HTML, SCSS, Javascript, Typescript, ng-bootstrap, SEO, Website/App Design', url: 'https://bestlongtermdisabilitylawyer.com/'},
    { id: '2', thumbnail: thumb02, caption: 'lighthousegroup.net', description: 'Joomla, HTML, CSS, Javascript, jQuery', url:'https://www.lighthousegroup.net/'},
    { id: '3', thumbnail: thumb05, caption: 'atmediastudio.com', description: 'Customized premium WordPress theme, HTML, CSS, Javascript, jQuery, PHP', url: 'http://www.atmediastudio.com/'},
    { id: '4', thumbnail: thumb03, caption: 'chambersdental.com', description: 'jQuery, Bootstrap, node.js, Pug, SCSS, Website Design', url: 'https://chambersdental.com/'},
    { id: '5', thumbnail: thumb04, caption: 'epinternalmed.com', description: 'jQuery, Bootstrap, node.js, Pug, SCSS, Website Design', url: 'http://epinternalmed.com/'},
    { id: '6', thumbnail: thumb06, caption: 'rtwebstudio.com', description: 'React SPA, Gatsby, Gatsby version migration, SCSS, Bootstrap', url: 'http://rtwebstudio.com/'}
];

class HomeIndex extends React.Component {

    render() {
        const siteTitle = this.props.data.site.siteMetadata.title
        const siteDescription = this.props.data.site.siteMetadata.description

        return (
            <Template>
                <div>
                    <Helmet>
                            <title>{siteTitle}</title>
                            <meta name="description" content={siteDescription} />
                    </Helmet>
                    <div id="main">
                        <section id="about">
                            <header className="major">
                                <h2>Your next web developer</h2>
                            </header>
                            <p>Ryan is a Seattle web developer with front end emphasis for over a decade, focused on creating professional websites and apps on many platforms (WordPress, Angular, React, Drupal, etc.) for a variety of business sectors. He has a usability-oriented approach to design with very clean and solid coding abilities, and excellent communication skills.</p>
                        </section>
                        <section id="work">
                            <h2>Recent Work</h2>
                            <Gallery images={DEFAULT_IMAGES.map(({ id, src, thumbnail, caption, description, url }) => ({
                                src,
                                thumbnail,
                                caption,
                                description,
                                url
                            }))} />
                        </section>
                        <section id="skills">
                            <h2>Skills</h2>
                            <ul className="skills-list">
                                <li className="box">React</li>
                                <li className="box">Drupal</li>
                                <li className="box">Joomla</li>
                                <li className="box">Magento</li>
                                <li className="box">Gatsby JS</li>
                                <li className="box">Pug</li>
                                <li className="box">Node.js</li>
                                <li className="box">JSON</li>
                                <li className="box">JSX</li>
                                <li className="box">HTML</li>
                                <li className="box">CSS</li>
                                <li className="box">SCSS</li>
                                <li className="box">Javascript</li>
                                <li className="box">PHP</li>
                                <li className="box">Python</li>
                                <li className="box">WordPress</li>
                                <li className="box">Shopify</li>
                                <li className="box">Linux</li>
                                <li className="box">Apache</li>
                                <li className="box">MySQL</li>
                                <li className="box">Website Design</li>
                                <li className="box">App Design</li>
                            </ul>
                        </section>
                        <section id="contact">
                            <h2>Contact</h2>
                            <p>Ryan is available for consultations, freelance work, and contract work.</p>
                            <div className="row">
                                <div className="">
                                    <ul className="labeled-icons">
                                        <li>
                                            <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                            970.819.3429
                                        </li>
                                        <li>
                                            <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                            <a href="mailto:ryan@rtwebstudio.com">ryan@rtwebstudio.com</a>
                                        </li>
                                        <li>
                                            <h3 className="icon fa-linkedin"><span className="label">LinkedIn</span></h3>
                                            <a href="https://www.linkedin.com/in/ryanteliczan/" target="_blank"><span className="label">Linked in</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Template>
        )
    }
}

export default HomeIndex

export const pageQuery = graphql`
    query PageQuery {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`